import moment from "moment";
import { useEffect, useState } from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { Button, Col, FormGroup, Input, Label } from "reactstrap";
import { Secret } from "../../../interfaces/sharepass";

interface Props {
  secret: Secret;
  updateSecret: (
    secretId: string,
    key: keyof Secret,
    value: any
  ) => Promise<boolean | undefined>;
}

const validateAndConvert = (details: any, field: string) => {
  let opt = "";
  let dateTime = 0;
  let qty = 0;

  if (details && details[field]) {
    if (details[field].absolute) {
      opt = "Specific date";
      dateTime = details[field].absolute * 1000;
    } else if (details[field].relative) {
      const relative = details[field].relative;

      if (relative % 86400 === 0) {
        opt = "Days";
        qty = relative / 86400;
      } else if (relative % 3600 === 0) {
        opt = "Hours";
        qty = relative / 3600;
      } else if (relative % 60 === 0) {
        opt = "Minutes";
        qty = relative / 60;
      }
    }
  }
  return { opt, dateTime, qty };
};

export const SecretDetailAvailability = ({ secret, updateSecret }: Props) => {
  const [editFrom, setEditFrom] = useState(false);
  const [editTtl, setEditTtl] = useState(false);

  const [ttl, setTtl] = useState(secret.ttl || { relative: 7 * 86400 });
  const [availableFrom, setAvailableFrom] = useState(
    secret.availableFrom || { relative: 0 }
  );
  const [ttlInput, setTtlInput] = useState(7);
  const [availableFromInput, setAvailableFromInput] = useState(0);
  const [loading, setLoading] = useState({ ttl: false, availableFrom: false });

  const [ttlMode, setTtlMode] = useState("days");
  const [availableFromMode, setAvailableFromMode] = useState("rightNow");

  const handleInputChange = (e: any, field: string) => {
    const value = parseInt(e.target.value, 10);
    if (field === "ttl") {
      setTtlInput(value);
      setTtl((prev) => ({ ...prev, relative: value * 86400 }));
    } else if (field === "availableFrom") {
      setAvailableFromInput(value);
      setAvailableFrom((prev) => ({ ...prev, relative: value * 86400 }));
    }
  };

  const handleDateChange = (date: any, field: string) => {
    const timestamp = new Date(date._d).getTime() / 1000;
    if (field === "ttl") {
      setTtl((prev) => ({ ...prev, absolute: timestamp, relative: undefined }));
    } else if (field === "availableFrom") {
      setAvailableFrom((prev) => ({
        ...prev,
        absolute: timestamp,
        relative: undefined,
      }));
    }
  };

  const handleSave = async (field: string) => {
    setLoading((prev) => ({ ...prev, [field]: true }));
    if (field === "ttl") {
      const value =
        ttlMode === "absolute"
          ? { absolute: ttl.absolute }
          : { relative: ttl.relative };
      const isUpdated = await updateSecret(secret.secretId, "ttl", value);
      setLoading((prev) => ({ ...prev, [field]: !isUpdated }));
    } else if (field === "availableFrom") {
      const value =
        availableFromMode === "absolute"
          ? { absolute: availableFrom.absolute }
          : { relative: availableFrom.relative };
      const isUpdated = await updateSecret(
        secret.secretId,
        "availableFrom",
        value
      );
      setLoading((prev) => ({ ...prev, [field]: !isUpdated }));
    }
  };

  useEffect(() => {
    if (secret.ttl) {
      setTtl(secret.ttl);
      setTtlInput(secret.ttl.relative ? secret.ttl.relative / 86400 : 7);
    }
    if (secret.availableFrom) {
      setAvailableFrom(secret.availableFrom);
      setAvailableFromInput(
        secret.availableFrom.relative
          ? secret.availableFrom.relative / 86400
          : 0
      );
    }
  }, [secret]);

  const ttlValidation = validateAndConvert(secret, "ttl");
  const availableFromValidation = validateAndConvert(secret, "availableFrom");

  // Timezone
  const [timeZone, setTimeZone] = useState("");

  useEffect(() => {
    const currentTimeZone = Intl.DateTimeFormat()
      .resolvedOptions()
      .timeZone.replace("_", " ");

    setTimeZone(currentTimeZone);
  }, []);

  const formattedAvailableFrom = moment(secret.availableFrom * 1000).format(
    "DD/MM/YY hh:mm A"
  );

  const formattedTTL = moment(secret.expiry * 1000).format("DD/MM/YY hh:mm A");

  return (
    <div>
      <div className="">
        <h5>
          <div className="flex react-table">
            <Col md={4} className="right" style={{ paddingTop: "10px" }}>
              <b>Availability Settings</b>
              <br />
              <b
                style={{
                  fontWeight: "normal",
                }}
              >
                {timeZone}
              </b>
            </Col>
            <Col md="8">
              {/* Available from */}
              <FormGroup className="flex">
                <div style={{ width: editFrom ? "150px" : "320px" }}>
                  <Label
                    style={{
                      display: "grid",
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                  >
                    Start date
                  </Label>
                  {editFrom ? (
                    <Input
                      type="select"
                      value={availableFromMode}
                      onChange={(e) => setAvailableFromMode(e.target.value)}
                    >
                      <option value="rightNow">Right Now</option>
                      <option value="minutes">Minutes</option>
                      <option value="hours">Hours</option>
                      <option value="days">Days</option>
                      <option value="absolute">Specific date</option>
                    </Input>
                  ) : (
                    <div
                      style={{
                        borderWidth: "0px",
                        padding: "0px",
                        textAlign: "start",
                      }}
                    >
                      {formattedAvailableFrom}
                    </div>
                  )}
                </div>
                {editFrom && availableFromMode !== "rightNow" && (
                  <div style={{ width: "150px", marginLeft: "10px" }}>
                    <div style={{ height: "23px" }} />
                    {availableFromMode === "absolute" ? (
                      <Datetime
                        inputProps={{ placeholder: "Select a date" }}
                        value={
                          availableFromValidation.dateTime
                            ? new Date(availableFromValidation.dateTime)
                            : new Date()
                        }
                        onChange={(date) =>
                          handleDateChange(date, "availableFrom")
                        }
                        dateFormat="DD/MM/YY"
                        timeFormat="HH:mm"
                      />
                    ) : (
                      <Input
                        type="number"
                        value={availableFromInput || ""}
                        onChange={(e) => handleInputChange(e, "availableFrom")}
                      />
                    )}
                  </div>
                )}
                {editFrom && (
                  <div style={{ width: "50px" }}>
                    <div style={{ height: "23px" }} />
                    <Button
                      color="primary"
                      className="btn-save"
                      style={{
                        width: "65px",
                        height: "38px",
                        padding: 0,
                        marginLeft: "10px",
                        marginTop: 0,
                        fontSize: "12px",
                      }}
                      disabled={loading.availableFrom}
                      onClick={() => handleSave("availableFrom")}
                    >
                      Save
                    </Button>
                  </div>
                )}
              </FormGroup>

              {/* TTL */}
              <br />
              <FormGroup className="flex">
                <div style={{ width: editFrom ? "150px" : "320px" }}>
                  <Label
                    style={{
                      display: "grid",
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                  >
                    End date
                  </Label>
                  {editTtl ? (
                    <Input
                      type="select"
                      value={ttlMode}
                      onChange={(e) => setTtlMode(e.target.value)}
                    >
                      <option value="minutes">Minutes</option>
                      <option value="hours">Hours</option>
                      <option value="days">Days</option>
                      <option value="absolute">Specific date</option>
                    </Input>
                  ) : (
                    <div
                      style={{
                        borderWidth: "0px",
                        padding: "0px",
                        textAlign: "start",
                      }}
                    >
                      {formattedTTL}
                    </div>
                  )}
                </div>
                {editFrom && ttlMode !== "absolute" && (
                  <div style={{ width: "150px", marginLeft: "10px" }}>
                    <div style={{ height: "23px" }} />
                    <Input
                      type="number"
                      value={ttlInput || ""}
                      onChange={(e) => handleInputChange(e, "ttl")}
                    />
                  </div>
                )}
                {editFrom && ttlMode === "absolute" && (
                  <div style={{ width: "150px", marginLeft: "10px" }}>
                    <div style={{ height: "23px" }} />
                    <Datetime
                      inputProps={{ placeholder: "Select a date" }}
                      value={
                        ttlValidation.dateTime
                          ? new Date(ttlValidation.dateTime)
                          : new Date()
                      }
                      onChange={(date) => handleDateChange(date, "ttl")}
                      dateFormat="DD/MM/YY"
                      timeFormat="HH:mm"
                    />
                  </div>
                )}
                {editFrom && (
                  <div style={{ width: "50px" }}>
                    <div style={{ height: "23px" }} />
                    <Button
                      color="primary"
                      className="btn-save"
                      disabled={loading.ttl}
                      onClick={() => handleSave("ttl")}
                      style={{
                        width: "65px",
                        height: "38px",
                        padding: 0,
                        marginLeft: "10px",
                        marginTop: 0,
                        fontSize: "12px",
                      }}
                    >
                      Save TTL
                    </Button>
                  </div>
                )}
              </FormGroup>
              <FormGroup className="flex">
                <div style={{ width: editFrom ? "150px" : "320px" }}>
                  <Label
                    style={{
                      display: "grid",
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                  >
                    Allow multiple uses
                  </Label>
                  {editTtl ? (
                    <div>Edit Allow multiple uses here</div>
                  ) : (
                    <div>
                      <div
                        style={{
                          borderWidth: "0px",
                          padding: "0px",
                          textAlign: "left",
                        }}
                      >
                        {secret.lockedByOwner ? "Yes" : "No"}
                      </div>
                    </div>
                  )}
                </div>
              </FormGroup>
              <FormGroup className="flex">
                <div style={{ width: editFrom ? "150px" : "320px" }}>
                  <Label
                    style={{
                      display: "grid",
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                  >
                    Lock after each uses
                  </Label>
                  {editTtl ? (
                    <div>Edit Lock after each uses</div>
                  ) : (
                    <div>
                      <div
                        style={{
                          borderWidth: "0px",
                          padding: "0px",
                          textAlign: "left",
                        }}
                      >
                        {secret.otl ? "Yes" : "No"}
                      </div>
                    </div>
                  )}
                </div>
              </FormGroup>
            </Col>
          </div>
        </h5>
      </div>
    </div>
  );
};
