import moment from "moment";
import { parseSecret } from "../../utils/secret";
import { SecretDetailsInput } from "./SecretDetailsInput";
import { SecretDetailsItem } from "./SecretDetailsItem";

export const CustomItemProfileModal = ({
  secretDetail,
  setSecretId,
  isLoading,
  updateSecret,
}: any) => {
  let secretDetailParsed = parseSecret([secretDetail]);

  secretDetail = secretDetailParsed[0];

  return (
    <div className="secret-detail">
      <hr />

      {secretDetail && secretDetail.creation && (
        <div className="secret-box-details">
          <SecretDetailsItem
            title="Id"
            value={secretDetail.secretId}
            hasIcon={false}
          />
          <SecretDetailsItem
            title="Type"
            value={secretDetail.type}
            hasIcon={false}
          />
          <SecretDetailsItem
            title="Creation date"
            value={moment(secretDetail.creation * 1000).format(
              "DD/MM/YY HH:mm:ss"
            )}
            hasIcon={false}
          />
          <SecretDetailsItem
            title="Modified date"
            value={
              secretDetail.creation !== secretDetail.lastModified
                ? moment(secretDetail.lastModified * 1000).format(
                    "DD/MM/YY HH:mm:ss"
                  )
                : "Never modified"
            }
            hasIcon={false}
          />
          <SecretDetailsItem
            title="Opened"
            value={secretDetail.opened}
            hasIcon={true}
          />
          <SecretDetailsItem
            title="Erased"
            value={secretDetail.secretErased}
            hasIcon={true}
          />
          <SecretDetailsInput
            title="Private description"
            value={secretDetail.privateDescription}
            updateSecret={updateSecret}
            secret={secretDetail}
          />
          <SecretDetailsInput
            title="Public description"
            value={secretDetail.publicDescription}
            updateSecret={updateSecret}
            secret={secretDetail}
          />
        </div>
      )}
    </div>
  );
};
