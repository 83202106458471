import { useState } from "react";
import "react-datetime/css/react-datetime.css";
import { Col, FormGroup, Label } from "reactstrap";
import { Secret } from "../../../interfaces/sharepass";

interface Props {
  secret: Secret;
  updateSecret: (
    secretId: string,
    key: keyof Secret,
    value: any
  ) => Promise<boolean | undefined>;
}

export const SecretDetailAuthorization = ({ secret, updateSecret }: Props) => {
  const [editFrom, setEditFrom] = useState(false);

  const { pin } = secret;

  if (!pin) return <></>;

  return (
    <div>
      <div className="secret-box-details">
        <h5>
          <div className="flex react-table">
            <Col md={4} className="right" style={{ paddingTop: "10px" }}>
              <b>Authorization</b>
            </Col>
            <Col md="8">
              {/* Available from */}
              <FormGroup className="flex">
                <div style={{ width: editFrom ? "150px" : "320px" }}>
                  <Label
                    style={{
                      display: "grid",
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                  >
                    {pin.type}
                  </Label>
                  {!editFrom && (
                    <div
                      style={{
                        borderWidth: "0px",
                        padding: "0px",
                        textAlign: "start",
                      }}
                    >
                      {pin.value}
                    </div>
                  )}
                </div>
              </FormGroup>
            </Col>
          </div>
        </h5>
      </div>
    </div>
  );
};
