import {
  faCheckCircle,
  faEye,
  faFilterCircleXmark,
  faKey,
  faRedoAlt,
  faTag,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import useAPIError from "../../commons/hooks/useAPIError";
import ReactTables from "../../components/ReactTable/ReactTable";
import { customColumns, initiaFilterlValues } from "../../constants/reactTable";
import { Context } from "../../context/auth/Context";
import { types } from "../../context/types";
import { Secret } from "../../interfaces/sharepass";
import {
  fetchSecretDelete,
  fetchSecretUpdate,
  fetchSecrets,
} from "../../services/services";
import { filterBody } from "../../utils/filter";
import { parseSecret } from "../../utils/secret";
import { SecretsFilter } from "../forms/SecretsFilter";
import { SecretDetailModal } from "./../components/SecretDetailModal";

interface StartId {
  id: string;
  timestamp: number;
  reload?: boolean;
}

interface FilterParams {
  otl?: boolean;
  type?: string;
  privateDescription?: string;
  publicDescription?: string;
  lockedByOwner?: boolean;
  label?: string; // An object that matches the codeName AND/OR the color attribute
  color?: string;
  pin?: boolean; // A boolean if the secret contains a pin (static or dynamic) in the pin attribute
  expired?: boolean; // A boolean that indicates the secrets that have the expiry value already expired
  ipList?: boolean; // A boolean if the secret contains at least one IP address restriction in the ipList attribute
  opened?: boolean;
}
interface BodySearch {
  bin: boolean;
  purpose: string;
  exclusiveStartId?: StartId;
  params?: FilterParams;
  asc?: boolean;
}

export const SecretList = () => {
  // Notify
  const { addNotify } = useAPIError();

  // Initial filter values, to save form filters
  const [initialValues, setInitialValues] = useState(initiaFilterlValues);

  const [data, setData] = useState([]);
  const [flagSearch, setFlagSearch] = useState(true);
  const [lastItem, setLastItem] = useState({ id: "", timestamp: 0 });
  const [lastItemRefresh, setLastItemRefresh] = useState({
    id: "",
    timestamp: 0,
  });

  const [sortAsc, setSortAsc] = useState(false);
  useEffect(() => {
    const getDataSorted = async () => {
      let body = {
        bin: false,
        purpose: "send",
        ...filterBody(initialValues),
      };

      await getSecrets(body);
    };

    setInitialValues((prev) => ({ ...prev }));

    getDataSorted();
  }, [sortAsc]);

  const history = useHistory();

  // Get name from context
  const { user, dispatch } = useContext(Context);

  const getSecrets = async (params?: BodySearch, previous?: boolean) => {
    setFlagSearch(false);
    setIsLoading(true);

    let body;

    if (!params) {
      body = {
        bin: false,
        purpose: "send",
      };
    } else {
      body = params;
    }
    if (sortAsc) body.asc = sortAsc;
    const { details, redirect, lastEvaluatedId } = await fetchSecrets(body);

    if (redirect) {
      dispatch({
        type: types.logout,
      });
      history.push("/auth/login");
    } else if (details) {
      const { secrets } = details;

      setLastItem(lastEvaluatedId);
      const parsedSecrets = parseSecret(secrets);
      const auxData = parseDataTable(parsedSecrets);

      if (lastEvaluatedId) setLastItemRefresh(lastEvaluatedId);

      setData((prevData) => {
        if (previous) return [...prevData, ...auxData];
        else return auxData;
      });
    }

    setIsLoading(false);
  };

  const parseDataTable = (dataTable: any) => {
    return dataTable.map((prop: any, key: number) => {
      return {
        id: prop.secretId,
        availableFrom: prop.availableFrom,
        created: (
          <div className="date-column">
            <Label id={`date-${prop.secretId}`}>{prop.dateFromNow}</Label>
            <UncontrolledTooltip
              delay={0}
              target={`date-${prop.secretId}`}
              placement="right"
            >
              {prop.dateToShow}
            </UncontrolledTooltip>
          </div>
        ),
        privDesc: (
          <>
            <div id={`privDesc-${prop.secretId}`}>
              {prop.privateDescription && prop.privateDescription.length > 20
                ? prop.privateDescription.substring(0, 17) + "..."
                : prop.privateDescription}
            </div>
            <UncontrolledTooltip
              delay={0}
              target={`privDesc-${prop.secretId}`}
              placement="right"
            >
              {prop.privateDescription}
            </UncontrolledTooltip>
          </>
        ),
        type: (
          <div>
            <FontAwesomeIcon id={`type-${prop.secretId}`} icon={prop.icon} />
            <UncontrolledTooltip
              delay={0}
              target={`type-${prop.secretId}`}
              placement="right"
            >
              {prop.iconName}
            </UncontrolledTooltip>
          </div>
        ),
        label:
          prop.label && prop.label.codeName ? (
            <div>
              <FontAwesomeIcon
                id="PinTooltip"
                className="right3"
                icon={faTag}
                color={prop.label.color}
              />{" "}
              {prop.label.codeName}
            </div>
          ) : (
            ""
          ),
        opened: (
          <div>
            <FontAwesomeIcon
              id={`opened-${prop.secretId}`}
              icon={prop.opened ? faCheckCircle : faTimesCircle}
              color={prop.opened ? "#00f2c3" : "#ccc"}
              className={prop.opened ? "opened" : ""}
            />
            <UncontrolledTooltip
              delay={0}
              target={`opened-${prop.secretId}`}
              placement="right"
            >
              {prop.opened ? "Opened" : "Not opened"}
            </UncontrolledTooltip>
          </div>
        ),
        locked: (
          <div
            id={`switch-${prop.secretId})`}
            onClick={() => {
              prop.statusAux !== "Expired" &&
                prop.statusAux !== "Erased" &&
                setSecretId(prop.secretId);
            }}
            checked={prop.lockedByOwner}
            disabled={
              prop.statusAux === "Expired" || prop.statusAux === "Erased"
            }
            className={`${
              prop.statusAux === "Expired" || prop.statusAux === "Erased"
                ? "btn-lock-unlock disabled"
                : "btn-lock-unlock"
            }`}
          >
            {prop.lockedAux}
          </div>
        ),
        status: (
          <Label className={`${prop.customClass}`}>{prop.statusAux}</Label>
        ),
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {user.purposeMode === 1 && (
              <Button
                onClick={() => {
                  history.push("/admin/envelope/receive?ts=" + Date.now(), {
                    id: prop.secretId,
                  });
                }}
                color="info"
                size="sm"
                className="btn-icon btn-link"
                disabled={!prop.filled}
              >
                <FontAwesomeIcon
                  icon={faKey}
                  color="#1d8cf8"
                  width="40px"
                  className="font-awesome top5 font-awesome"
                />
              </Button>
            )}
            <Button
              onClick={() => {
                setDetails((prevProps) => ({
                  showModal: true,
                  secret: prop,
                  id: prop.secretId,
                }));
              }}
              color="info"
              size="sm"
              className="btn-icon btn-link"
            >
              <FontAwesomeIcon
                icon={faEye}
                color="#1d8cf8"
                width="40px"
                className="font-awesome top5 font-awesome"
              />
            </Button>
            {/* <Button
                color="info"
                size="sm"
                className={classNames("btn-icon btn-link disabled")}
              >
                <i className="tim-icons icon-pencil" />
              </Button> */}
            <Button
              onClick={() => {
                warningWithConfirmMessage(prop.secretId);
              }}
              color="danger"
              size="sm"
              className="btn-icon btn-link"
            >
              <i className="tim-icons icon-trash-simple" />
            </Button>
          </div>
        ),
      };
    });
  };

  /*
   * Show alert before delete a secret
   */
  const [alert, setAlert] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const warningWithConfirmMessage = (id: string) => {
    setShowAlert(true);
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => {
          setAuxId(id);
        }}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="success"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        This action cannot be undone!
      </ReactBSAlert>
    );
  };

  /*
   * Hide alert when delete a secret
   */
  const hideAlert = () => {
    setShowAlert(false);
    setAlert({});
    setDetails({ secret: {}, id: "", showModal: false });
  };

  /*
   * Delete a secret
   */
  const [auxId, setAuxId] = useState("");
  useEffect(() => {
    // Delete from the database
    const deleteSecret = async () => {
      const body = { secretId: auxId };

      const { status, redirect } = await fetchSecretDelete(body);

      if (redirect) {
        dispatch({
          type: types.logout,
        });
        history.push("/auth/login");
      } else if (status === "Success") {
        const newData = data.filter((secret: any) => secret.id !== auxId);
        setData(newData);
        setAuxId("");

        setAlert(
          <ReactBSAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title="Deleted!"
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="success"
            btnSize=""
          >
            Your secret has been deleted.
          </ReactBSAlert>
        );
      }
    };

    const isDeleted = data.find((secret: any) => secret.id === auxId);
    if (auxId !== "" && isDeleted) {
      deleteSecret();
    }
  }, [auxId, data]);

  /*
   * Show modal with details of secret
   */
  const [details, setDetails] = useState({
    secret: {},
    id: "",
    showModal: false,
  });

  useEffect(() => {
    if (flagSearch) getSecrets();
  });

  useEffect(() => {
    const currentURL = new URLSearchParams(history.location.search);

    if (currentURL.get("show_details")) {
      const secretId = currentURL.get("show_details");

      setDetails({
        showModal: true,
        secret: {},
        id: secretId,
      });
    }
  }, []);

  /*
   * Update secret (locked field)
   */
  const [secretId, setSecretId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (isLoading) {
      setSecretId("");
      return;
    }
    if (secretId) {
      /* Update secret and data table */
      const updateSecret = async (lockedByOwner: boolean, newData: any) => {
        const body = {
          secretId,
          updateAttributes: {
            lockedByOwner,
          },
        };

        const { status, redirect, message } = await fetchSecretUpdate(body);

        /* Update data table */
        if (redirect) {
          dispatch({
            type: types.logout,
          });
          history.push("/auth/login");
        } else if (status === "Success") {
          addNotify("The secret was successfully updated", "success");
          setData(newData);
          setIsLoading(false);
        } else if (status !== "Success") {
          addNotify(`${status}\n${message}`, "danger");
          setIsLoading(false);
        }
      };

      setIsLoading(true);

      let newData: any = [...data];
      let secret: any = data.find((s: any) => {
        return s.id === secretId;
      });

      if (secret) {
        const locked = { ...secret.locked };
        let props = {
          ...locked.props,
          checked: !locked.props.checked,
          children: !locked.props.checked ? (
            <i className="fa fa-lock text-danger"></i>
          ) : (
            <i className="fa fa-unlock text-success"></i>
          ),
        };
        const finalLocked = { ...locked, props };

        const status = { ...secret.status };

        const isPlanned = secret.availableFrom > Date.now() / 1000;

        let statusProps = {
          ...status.props,
          children: locked.props.checked
            ? isPlanned
              ? "Planned"
              : "Available"
            : "Locked",
          className: locked.props.checked && !isPlanned ? "success" : "warning",
        };
        const finalStatus = { ...status, props: statusProps };

        const newSecret = {
          ...secret,
          locked: finalLocked,
          status: finalStatus,
        };

        const i = data.findIndex((item: any) => item.id === secretId);
        newData[i] = newSecret;

        /* Update secret on the database */
        updateSecret(!locked.props.checked, newData);
        setSecretId("");
      }
    }
  }, [secretId, data, isLoading, addNotify]);

  const updateSecret = async (
    secretId: string,
    key: keyof Secret,
    value: any
  ): Promise<boolean | undefined> => {
    const body: any = {
      secretId: secretId,
      updateAttributes: {
        [key]: value,
      },
    };

    const { status, redirect, message } = await fetchSecretUpdate(body);

    if (redirect) {
      dispatch({
        type: types.logout,
      });
      history.push("/auth/login");
      return false;
    } else if (status === "Success") {
      let newData: any = [...data];
      let secretAux: any = data.find((s: any) => s.id === secretId);

      console.log("secretAux", secretAux);

      let auxKey: string = key;
      if (key === "privateDescription") {
        auxKey = "privDesc";
      } else if (key === "publicDescription") {
        auxKey = "publicDesc";
      }

      const newSecret = {
        ...secretAux,
        [auxKey]: value,
      };

      console.log("newSecret", newSecret);

      const i = data.findIndex((item: any) => item.id === secretId);
      newData[i] = newSecret;

      setData(newData);
      addNotify("The secret was successfully updated", "success");
      setIsLoading(false);
      return true;
    } else {
      addNotify(`${status}\n${message}`, "danger");
      return false;
    }
  };

  // TODO
  // Try to put scrollbar on top by useRef
  const reloadList = async () => {
    if (isLoading) return;
    let body = { bin: false, purpose: "send", ...filterBody(initialValues) };

    await getSecrets(body);
  };

  const fetchMoreData = async () => {
    setIsLoading(true);

    const body = {
      bin: false,
      purpose: "send",
      exclusiveStartId: lastItem,
      ...filterBody(initialValues),
    };

    await getSecrets(body, true);
  };

  const [isFilterActive, setIsFilterActive] = useState(false);

  // Remove all filters
  const removeFilter = async () => {
    setInitialValues(initiaFilterlValues);

    if (isFilterActive) {
      setIsFilterActive(false);
      await getSecrets();
    }
  };

  const dataFinal = React.useMemo(() => data, [data]);
  const [lastData, setLastData] = useState([]);
  useEffect(() => {
    setLastData(dataFinal);
  });

  return (
    <>
      <div className="content react-table">
        {showAlert && alert}
        {details.showModal && (
          <SecretDetailModal
            detail={details}
            setDetails={setDetails}
            setSecretId={setSecretId}
            isLoading={isLoading}
            setShowAlert={setShowAlert}
            setAlert={setAlert}
            hideAlert={hideAlert}
            updateSecret={updateSecret}
          />
        )}
        <Row className="mt-2">
          <Col xs={12} md={12}>
            <Card className="m0">
              <CardBody className="overflow-x-auto">
                <div className="flex">
                  <Col xs={6} md={6} className="p0">
                    <SecretsFilter
                      getSecrets={getSecrets}
                      isLoading={isLoading}
                      initialValues={initialValues}
                      setInitialValues={setInitialValues}
                      setIsFilterActive={setIsFilterActive}
                      isFilterActive={isFilterActive}
                    />
                  </Col>
                  <Col xs={6} md={6}>
                    <Button
                      className="btn-fill remove-filter"
                      color="primary"
                      disabled={isLoading}
                      id="idTimes"
                      onClick={removeFilter}
                    >
                      <FontAwesomeIcon
                        icon={faFilterCircleXmark}
                        color={isFilterActive ? "#bfbfc5" : ""}
                      />
                    </Button>
                    <UncontrolledTooltip
                      delay={0}
                      target="idTimes"
                      placement="right"
                    >
                      Clear filter
                    </UncontrolledTooltip>
                    <Button
                      className="btn-fill m0"
                      color="primary"
                      disabled={isLoading}
                      onClick={reloadList}
                      id="idRefreshBtn"
                    >
                      <FontAwesomeIcon icon={faRedoAlt} color="#fff" />
                    </Button>
                    <UncontrolledTooltip
                      delay={0}
                      target="idRefreshBtn"
                      placement="right"
                    >
                      Refresh
                    </UncontrolledTooltip>
                  </Col>
                </div>
                <hr />
                <ReactTables
                  columns={customColumns}
                  data={lastData}
                  isLoading={isLoading}
                  lastItem={lastItem}
                  update={fetchMoreData}
                  sortAsc={sortAsc}
                  sortByAsc={() => !isLoading && setSortAsc(!sortAsc)}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
