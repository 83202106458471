import {
  faCheckCircle,
  faPencilAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useState } from "react";
import { Col, Input } from "reactstrap";
import { Secret } from "../../interfaces/sharepass";

interface Props {
  title: string;
  value: string;
  secret: Secret;
  updateSecret: (
    secretId: string,
    key: keyof Secret,
    value: any
  ) => Promise<boolean | undefined>;
}

export const SecretDetailsInput = ({
  title,
  value,
  secret,
  updateSecret,
}: Props) => {
  const [editing, setEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(value);
  const [oldValue, setOldValue] = useState(value);

  const handleEdit = () => {
    setEditing(true);
  };

  const handleSave = async () => {
    if (oldValue === editedValue) {
      setEditing(false);
      return;
    }

    if (editedValue.length > 99) return;

    // Determine which property to update based on the title
    const key =
      title === "Private description"
        ? "privateDescription"
        : "publicDescription";

    const newValue = editedValue === "" ? null : editedValue;
    const isUpdated = await updateSecret(secret.secretId, key, newValue);

    if (isUpdated) {
      let newSecret = { ...secret };
      newSecret[key] = newValue;
      setOldValue(editedValue);
    } else {
      setEditedValue(oldValue);
    }

    setEditing(false);
  };

  const handleCancel = () => {
    setEditedValue(oldValue);
    setEditing(false);
  };

  return (
    <h5>
      <div className="flex">
        <Col md={4} className="right">
          <b>{title}</b>
        </Col>
        <Col md={6} className="left form-formik">
          {editing ? (
            <>
              <Input
                type="text"
                value={editedValue}
                onChange={(e) => setEditedValue(e.target.value)}
                style={{ height: "18px" }}
              />
              {editedValue && editedValue.length > 99 && (
                <label className="error left top0 font14">
                  Cannot be longer than 99 characters
                </label>
              )}
            </>
          ) : value !== editedValue ? (
            editedValue
          ) : (
            value
          )}
        </Col>
        <Col md={2} className="left">
          {editing && (
            <div>
              <Col md={1} className="left">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color="#00f2c3"
                  onClick={handleSave}
                  className="cursor-p secret-details-icons"
                />
              </Col>
              <Col md={1} className="left">
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  color="#f63b3b"
                  onClick={handleCancel}
                  className="cursor-p secret-details-icons"
                />
              </Col>
            </div>
          )}
          {!editing && secret.expiry > moment().unix() && (
            <FontAwesomeIcon
              icon={faPencilAlt}
              onClick={handleEdit}
              color="#1d8cf8"
              className="cursor-p secret-details-icons"
            />
          )}
        </Col>
      </div>
    </h5>
  );
};
