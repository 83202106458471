import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardBody, Col, Row } from "reactstrap";

const ComingSoon = () => {
  return (
    <div className="content react-table">
      <Row>
        <Col className="text-center" md="12">
          <Card style={{ height: "calc(100vh - 150px)" }}>
            <CardBody
              style={{
                height: "100%",
                maxHeight: "calc(100vh - 200px)",
                overflow: "hidden",
              }}
            >
              <div>
                <Row>
                  <Col style={{ marginTop: "100px" }} md="12">
                    <h3>Coming Soon</h3>
                    <FontAwesomeIcon
                      icon={faCog}
                      spin={true}
                      size="3x"
                      className="left5 cursor-p"
                    />
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ComingSoon;
