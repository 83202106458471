import {
  faCheckCircle,
  faPencilAlt,
  faTimesCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";
import { Col, FormGroup } from "reactstrap";
import { Secret } from "../../../interfaces/sharepass";
import { checkIpValue } from "../../../utils";

interface Props {
  secret: Secret;
  updateSecret: (
    secretId: string,
    key: keyof Secret,
    value: any
  ) => Promise<boolean | undefined>;
}

export const SecretDetailIpList = ({ secret, updateSecret }: Props) => {
  let user: any = localStorage.getItem("user");

  const [editIpList, setEditIpList] = useState(false);

  const validateIPaddress = (ipaddress: string) =>
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      ipaddress
    );

  const [selectIp, setSelectedIp] = useState<string[]>(secret.ipList || []);
  const [value, setValue] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const addIpAddress = async (ipAddress: string) => {
    if (
      selectIp.length <= 9 &&
      validateIPaddress(ipAddress) &&
      !selectIp.includes(ipAddress)
    ) {
      setIsAdding(true);
      const newIps = [...selectIp, ipAddress];
      const isUpdated = await updateSecret(secret.secretId, "ipList", newIps);

      if (isUpdated) {
        setSelectedIp(newIps);
        setValue(""); // Clear input value
      }
      setIsAdding(false);
      setEditIpList(false);
    }
  };

  const removeIp = async (ip: string) => {
    setIsDeleting(true);
    const newIps = selectIp.filter((ipAux) => ipAux !== ip);
    const isUpdated = await updateSecret(
      secret.secretId,
      "ipList",
      newIps.length > 0 ? newIps : null
    );

    if (isUpdated) {
      setSelectedIp(newIps);
    }
    setIsDeleting(false);
    setEditIpList(false);
  };

  useEffect(() => {
    if (secret.ipList) {
      setSelectedIp(secret.ipList);
    }
  }, [secret]);

  return (
    <div>
      <h5>
        <div className="flex react-table form-formik">
          <Col md={4} className="right" style={{ paddingTop: "10px" }}>
            <b>Allowed IPs (10 max)</b>
          </Col>

          {!editIpList && (
            <>
              <Col md="7">
                <div
                  className="box-ips"
                  style={{
                    display: "flex", // Make this a flex container
                    flexWrap: "wrap", // Allows items to wrap to the next line if space runs out
                    height: "60px",
                    alignItems: "center", // Align items vertically
                  }}
                >
                  {selectIp.map((ip, index) => (
                    <div key={`ip ${index}`} className="form-formik">
                      <p
                        className="ip-address-p"
                        style={{
                          color: JSON.parse(user).lightTheme
                            ? "#1d253b"
                            : "#fff",
                          padding: 0,
                          borderWidth: 0,
                          display: "inline-block", // Ensure they're laid out in line
                          marginRight: "10px", // Increase margin if needed for spacing
                        }}
                      >
                        {ip}{" "}
                        {
                          // Add a space after the IP address
                          index < selectIp.length - 1 ? "," : ""
                        }
                      </p>
                    </div>
                  ))}
                </div>
              </Col>
              <Col md={2} className="left">
                {editIpList && (
                  <div>
                    <Col md={1} className="left">
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        color="#00f2c3"
                        onClick={() => setEditIpList(true)}
                        className="cursor-p secret-details-icons"
                      />
                    </Col>
                    <Col md={1} className="left">
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        color="#f63b3b"
                        onClick={() => setEditIpList(false)}
                        className="cursor-p secret-details-icons"
                      />
                    </Col>
                  </div>
                )}
                {!editIpList && secret.expiry > moment().unix() && (
                  <FontAwesomeIcon
                    icon={faPencilAlt}
                    onClick={() => setEditIpList(true)}
                    color="#1d8cf8"
                    className="cursor-p secret-details-icons"
                  />
                )}
              </Col>
            </>
          )}

          {editIpList && (
            <Col md="8">
              <FormGroup>
                <div className="form-formik flex">
                  <ReactInputMask
                    style={{ width: "85%", height: "40px" }}
                    name="ipAddress"
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                    formatChars={{ "9": "[0-9.]" }}
                    className="custon-input-mask"
                    mask="999999999999999"
                    maskChar={null}
                    alwaysShowMask={false}
                    beforeMaskedValueChange={(newState, oldState) => {
                      let value = newState.value;
                      const oldValue = oldState.value;
                      let selection = newState.selection;
                      let cursorPosition = selection ? selection.start : null;
                      const result = checkIpValue(value);
                      if (!result) {
                        value = value.trim();
                        // try to add . before the last char to see if it is valid ip address
                        const newValue =
                          value.substring(0, value.length - 1) +
                          "." +
                          value.substring(value.length - 1);
                        if (checkIpValue(newValue)) {
                          cursorPosition++;
                          selection = {
                            start: cursorPosition,
                            end: cursorPosition,
                          };
                          value = newValue;
                        } else {
                          value = oldValue;
                        }
                      }

                      return {
                        value,
                        selection,
                      };
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Col md={1} className="left">
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        color="#00f2c3"
                        onClick={() => {
                          !isAdding && addIpAddress(value);
                        }}
                        className="cursor-p secret-details-icons"
                      />
                    </Col>
                    <Col md={1} className="left">
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        color="#f63b3b"
                        onClick={() => {
                          !isAdding && setEditIpList(false);
                        }}
                        className="cursor-p secret-details-icons"
                      />
                    </Col>
                  </div>
                </div>
              </FormGroup>
              {selectIp.length > 0 && (
                <div className="box-ips" style={{ height: "70px" }}>
                  {selectIp.map((ip, index) => (
                    <div className="flex" key={`ip ${index}`}>
                      <p
                        className="ip-address-p"
                        style={{
                          color: JSON.parse(user).lightTheme
                            ? "#1d253b"
                            : "#fff",
                          minWidth: "120px",
                          marginRight: "5px",
                          borderWidth: 0,
                        }}
                      >
                        {ip}
                      </p>
                      <div className="display-flex">
                        <FontAwesomeIcon
                          className="cursor-p"
                          icon={faTrash}
                          color="#ff4757"
                          onClick={() => !isDeleting && removeIp(ip)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </Col>
          )}
        </div>
      </h5>
    </div>
  );
};
