import { Col } from "reactstrap";
import { Secret } from "../../interfaces/sharepass";
import { canEditSecret, parseSecret } from "../../utils/secret";
import { SecretDetailAuthorization } from "./SecuritySettings/SecretDetailAuthorization";
import { SecretDetailAvailability } from "./SecuritySettings/SecretDetailAvailability";
import { SecretDetailIpList } from "./SecuritySettings/SecretDetailIPList";

interface Props {
  tabName: string;
  dispatchProfile: any;
  profile: any;
  secretDetail: any;
  setSecretId: (id: string) => void;
  isLoading: boolean;
  updateSecret: (
    secretId: string,
    key: keyof Secret,
    value: any
  ) => Promise<boolean | undefined>;
}

export const SecretSettingsTab = ({
  secretDetail,
  setSecretId,
  isLoading,
  updateSecret,
}: Props) => {
  let secretDetailParsed = parseSecret([secretDetail]);

  secretDetail = secretDetailParsed[0];

  //console.log("secretDetail: ", secretDetail);
  const isEditable = canEditSecret(secretDetail);

  return (
    <div
      className="secret-detail"
      style={{ height: "332px", overflowY: "scroll", overflowX: "hidden" }}
    >
      <hr />
      {/* IP List */}
      <SecretDetailIpList secret={secretDetail} updateSecret={updateSecret} />

      {/* IP List */}

      {/* Lock/Unlock */}
      {secretDetail && secretDetail.creation && (
        <h5>
          <div className="flex react-table">
            <Col md={4} className="right">
              <b>Lock/Unlock</b>
            </Col>
            <Col md={8} className="text-center">
              <div
                id={`switch-${secretDetail.secretId})`}
                onClick={() => {
                  if (!isLoading && isEditable) {
                    secretDetail.lockedByOwner = !secretDetail.lockedByOwner;
                    let secretDetailParsed = parseSecret([secretDetail]);
                    secretDetail = secretDetailParsed[0];
                    setSecretId(secretDetail.secretId);
                  }
                }}
                checked={secretDetail.lockedByOwner}
                disabled={isLoading || !isEditable}
                className={`btn-lock-unlock ${
                  !isEditable || isLoading ? "disabled" : ""
                }`}
              >
                {secretDetail.lockedAux}
              </div>
            </Col>
          </div>
        </h5>
      )}

      <SecretDetailAvailability
        secret={secretDetail}
        updateSecret={updateSecret}
      />
      <SecretDetailAuthorization
        secret={secretDetail}
        updateSecret={updateSecret}
      />

      {/* One Time Link */}
    </div>
  );
};
